<script lang="ts" setup>
import { CfgButton, CfgDialog, CfgModalSizes, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { MessageRemoveIcon } from '@cfgtech/icons'

defineProps<{
  title?: string
  description?: string
  showButton?: boolean
}>()

const isOpened = defineModel<boolean>('isOpened', {
  default: false,
  type: Boolean as PropType<boolean>,
})

function closeModal() {
  isOpened.value = false
}
</script>

<template>
  <CfgDialog
    v-model:opened="isOpened"
    :size="CfgModalSizes.Medium"
  >
    <template #title>
      <div class="flex flex-col items-center justify-center gap-y-4">
        <MessageRemoveIcon class="text-[80px] text-[#E8B1B1]" />

        <slot name="title">
          <CfgTypography
            class="text-center !font-normal"
            :html="title"
            :size="CfgTypographySizes.h2"
          />
        </slot>
      </div>
    </template>

    <template #description>
      <slot name="description">
        <CfgTypography
          class="mt-4 block text-center"
          :html="description"
          :size="CfgTypographySizes.md"
        />
      </slot>
    </template>

    <slot
      v-if="showButton"
      :close="closeModal"
      name="button"
    >
      <div class="flex">
        <CfgButton class="small w-full" @click="closeModal">
          {{ $t('common.close') }}
        </CfgButton>
      </div>
    </slot>
  </CfgDialog>
</template>
